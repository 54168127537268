/*
 * CB Link Mixins
 */

 @use '../includes/apricot-variable' as *;

@mixin default-link() {
  /* blue  */
  color: $link-color;
  text-decoration: underline;

  &:hover,
  &.cb-hover {
    color: $link-hover-color;
    text-decoration: none;
  }

  &:active,
  &.cb-active {
    color: $link-active-color;
  }

  &:not(.cb-btn):visited,
  &:not(.cb-btn).cb-visited {
    color: $link-visited-color;
  }

  &.cb-disabled {
    @include disabled-link();
  }
}

@mixin black-link() {
  /* black */
  color: $link-color-black;

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus {
    color: $link-color-black;
  }

  &:active,
  &.cb-active {
    color: $link-active-color-black;
  }

  &:not(.cb-btn):visited,
  &:not(.cb-btn).cb-visited {
    color: $link-color-black;
  }

  &.cb-disabled {
    @include disabled-link();
  }
}

@mixin white-link() {
  /* white */
  color: $white !important;

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus {
    color: $white;
  }

  &:active,
  &.cb-active {
    color: $white;
  }

  &:not(.cb-btn):visited,
  &:not(.cb-btn).cb-visited {
    color: $white;
  }

  &.cb-disabled {
    @include disabled-link();
  }
}

@mixin disabled-link() {
  cursor: not-allowed;
  color: $gray3 !important;
  text-decoration: none !important;

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus,
  &.cb-active,
  &:visited,
  &.cb-visited {
    color: $white;
    color: $gray3 !important;
    text-decoration: none !important;
  }
}

/* ------------------------------------ PALETTE */
@mixin palette-link($color, $activeColor) {
  /* black */
  color: $color;

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus {
    color: $color;
  }

  &:active,
  &.cb-active {
    color: $activeColor;
  }

  &:not(.cb-btn):visited,
  &:not(.cb-btn).cb-visited {
    color: $color;
  }

  &.cb-disabled {
    @include disabled-link();
  }
}
