/*
 * CB Border
 */

@use 'sass:color';

@use 'apricot-variable' as *;
@use '../mixins/breakpoint' as *;

.cb-border-color {
  border-color: $gray4;
}

.cb-border-width {
  border-width: 1px !important;
}

.cb-border-style {
  border-style: solid;
  border-width: 0;
}

/* ------------------------------------ BORDER: 1px */
.cb-border {
  border: $border-width solid $border-color;
}

.cb-no-border {
  border: 0 !important;
}

.cb-no-border-radius {
  border-radius: 0 !important;
}

.cb-no-border-radius-tl {
  border-top-left-radius: 0 !important;
}

.cb-no-border-radius-tr {
  border-top-right-radius: 0 !important;
}

.cb-no-border-radius-bl {
  border-bottom-left-radius: 0 !important;
}

.cb-no-border-radius-br {
  border-bottom-right-radius: 0 !important;
}

@each $type in $border-type {
  .cb-border-#{$type} {
    border-#{$type}: $border-width solid $border-color;
  }

  .cb-no-border-#{$type} {
    border-#{$type}: 0 !important;
  }
}

/* ------------------------------------ BORDER STYLE */
@each $style in $border-style {
  .cb-border-#{$style} {
    border-style: $style;
    border-width: 0;
  }
}

/* ------------------------------------ BORDER WIDTH and POSITION */
@each $width in $borders-width {
  .cb-border-#{$width} {
    border-width: #{$width}px !important;
  }

  @each $type in $border-type {
    .cb-border-#{$type}-#{$width} {
      border-#{$type}-width: #{$width}px !important;
    }
  }
}

/* ------------------------------------ RESPONSIVE */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .cb-border-#{$breakpoint} {
      border: $border-width solid $border-color;
    }

    .cb-no-border-#{$breakpoint} {
      border: 0 !important;
    }

    @each $type in $border-type {
      .cb-border-#{$breakpoint}-#{$type} {
        border-#{$type}: $border-width solid $border-color;
      }

      .cb-no-border-#{$breakpoint}-#{$type} {
        border-#{$type}: 0 !important;
      }
    }
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .cb-border-#{$breakpoint}-up {
      border: $border-width solid $border-color;
    }

    .cb-no-border-#{$breakpoint}-up {
      border: 0 !important;
    }

    @each $type in $border-type {
      .cb-border-#{$breakpoint}-up-#{$type} {
        border-#{$type}: $border-width solid $border-color;
      }

      .cb-no-border-#{$breakpoint}-up-#{$type} {
        border-#{$type}: 0 !important;
      }
    }
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    .cb-border-#{$breakpoint}-down {
      border: $border-width solid $border-color;
    }

    .cb-no-border-#{$breakpoint}-down {
      border: 0 !important;
    }

    @each $type in $border-type {
      .cb-border-#{$breakpoint}-down-#{$type} {
        border-#{$type}: $border-width solid $border-color;
      }

      .cb-no-border-#{$breakpoint}-down-#{$type} {
        border-#{$type}: 0 !important;
      }
    }
  }
}

/* ------------------------------------ BORDER RADIUS */
.cb-border-radius {
  border-radius: $border-radius;
}

.cb-border-radius-8 {
  border-radius: $border-radius;
}

.cb-border-radius-16 {
  border-radius: 16px;
}

.cb-border-radius-50 {
  border-radius: 50%;
}

@each $size in $radius-size {
  .cb-border-radius-top-left-#{$size} {
    border-top-left-radius: #{$size}px !important;
  }
  .cb-border-radius-top-right-#{$size} {
    border-top-right-radius: #{$size}px !important;
  }
  .cb-border-radius-bottom-left-#{$size} {
    border-bottom-left-radius: #{$size}px !important;
  }
  .cb-border-radius-bottom-right-#{$size} {
    border-bottom-right-radius: #{$size}px !important;
  }
}

.cb-border-radius-top-left-50 {
  border-top-left-radius: 50% !important;
}

.cb-border-radius-top-right-50 {
  border-top-right-radius: 50% !important;
}

.cb-border-radius-bottom-left-50 {
  border-bottom-left-radius: 50% !important;
}

.cb-border-radius-bottom-right-50 {
  border-bottom-right-radius: 50% !important;
}

/* ------------------------------------ BOX SHADOW */
.cb-box-shadow {
  box-shadow: 0 0 24px rgb(0 0 0 / 20%);
}

.cb-box-shadow-right {
  box-shadow: 4px 0 4px -2px rgb(0 0 0 / 10%);
}

.cb-box-shadow-left {
  box-shadow: -4px 0 4px -2px rgb(0 0 0 / 10%);
}

.cb-box-shadow-bottom {
  box-shadow: 0 4px 4px -2px rgb(0 0 0 / 10%);
}

.cb-box-shadow-top {
  box-shadow: 0 -4px 4px -2px rgb(0 0 0 / 10%);
}

/* ------------------------------------ LIGHT */
.cb-box-shadow-light {
  box-shadow: 0 0 12px rgb(0 0 0 / 10%);
}

.cb-box-shadow-top-light {
  box-shadow: 0 -5px 11px -5px rgb(0 0 0 / 10%);
}

.cb-box-shadow-left-light {
  box-shadow: -5px 0 11px -5px rgb(0 0 0 / 10%);
}

.cb-box-shadow-right-light {
  box-shadow: 5px 0 11px -5px rgb(0 0 0 / 10%);
}

.cb-box-shadow-bottom-light {
  box-shadow: 0 5px 11px -5px rgb(0 0 0 / 10%);
}

/* ------------------------------------ RESET */
.cb-no-box-shadow {
  box-shadow: none !important;
}

/* ------------------------------------ SHADE and TINT */
.cb-blue5-tint-1-border {
  border-color: $blue5-tint1 !important;
}

.cb-blue5-tint-2-border {
  border-color: $blue5-tint2 !important;
}

.cb-blue5-shade-1-border {
  border-color: $blue5-shade1 !important;
}

.cb-blue5-shade-2-border {
  border-color: $blue5-shade2 !important;
}

@each $key, $value in $tint-colors {
  .cb-#{$key}-tint-1-border {
    border-color: color.mix($white, $value, 90%) !important;
  }
}

@each $key, $value in $tint-colors {
  .cb-#{$key}-tint-2-border {
    border-color: color.mix($white, $value, 95%) !important;
  }
}
