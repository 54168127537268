/*
 * CB Variables
 */

@use 'sass:math';
@use 'sass:map';

/* ------------------------------------ NAMESPACE */
$apricot-namespace: 'cb-apricot';

/* ------------------------------------ PATHS */

$font-path: '../../fonts/';
$illustrations-path: '../../assets/illustrations/';

/* ------------------------------------ ICONS, Font File */

$icons-file-a: 'cb-icons-a-4_7_4';
$icons-font-family-group-a: 'CB Icons GA 4_7_4';
$icons-file-b: 'cb-icons-b-4_7_4';
$icons-font-family-group-b: 'CB Icons GB 4_7_4';

/* ------------------------------------ WHITE */
$white: #fff;

/* ------------------------------------ BLACK */

$black1: #1e1e1e;
$black2: #151515;
$black: #000;

/* ------------------------------------ BLUE */

$blue1: #006298;

/* Link Color , blue accent1 */
$blue2: #0077c8;
$blue3: #009cde;
$blue4: #71c5e8;
$outline-color: #7ab6fd;
$blue5: #324dc7;
$blue5-shade1: #28369a;
$blue5-shade2: #1b2264;

/* 90 */
$blue5-tint1: #e6edf8;

/* 95 */
$blue5-tint2: #f5f7fc;
$blue5-accent1: #c7dbff;
$blue5-accent2: #adc7ff;
$blue5-accent3: #87abff;
$blue5-accent4: #618fff;
$blue5-accent5: #406cf2;
$blue2-accent1: #bde4f4;
$blue2-accent2: #71c5e8;
$blue2-accent3: #4fb8e5;
$blue2-accent4: #009cde;
$blue2-accent5: #008bd6;

/* ------------------------------------ PURPLE */

$purple1: #702f8a;
$purple2: #a05eb5;
$purple3: #85144b;
$purple1-accent1: #e3cceb;
$purple1-accent2: #c7a1d4;
$purple1-accent3: #b07dc4;
$purple1-accent4: #9c59b2;
$purple1-accent5: #8747a1;

/* ------------------------------------ GREEN */

$green1: #3a913f;
$green2: #6cc24a;

/* Toast */
$green3: #1d7846;

/* ------------------------------------ GRAY */
$gray1: #505050;
$gray2: #888;
$gray3: #b2b2b2;
$gray4: #d9d9d9;

/* digital */
$gray5: #f0f0f0;
$gray6: #383838;
$gray7: #6b6b6b;
$gray8: #9e9e9e;

/* ------------------------------------ YELLOW */
$yellow: #fedb00;
$yellow1: $yellow;

/* highlight table cell */
$yellow2: #fdf4ba;

/* Active button */
$yellow3: #e5c500;

/* ------------------------------------ ORANGE */

$orange1: #e57200;

/* ------------------------------------ RED */

/* Toast */
$red1: #ab2334;

/* ------------------------------------ SUCCESS */
$success: $green3;
$success-shade1: #155934;
$success-shade2: #0d3921;
$success-tint1: #c9dfd3;
$success-tint2: #f4f8f6;
$success-accent1: #c9dfd3;
$success-accent2: #a8ccb8;
$success-accent3: #8ab89e;
$success-accent4: #6ea884;
$success-accent5: #409663;

/* ------------------------------------ WARNING */
$warning: $red1;
$warning-shade1: #7f1a26;
$warning-shade2: #521019;
$warning-tint1: #eacace;
$warning-tint2: #fbf4f5;
$warning-accent1: #eacace;
$warning-accent2: #e0abb0;
$warning-accent3: #d68a91;
$warning-accent4: #cc6b73;
$warning-accent5: #bd4a52;

/* ------------------------------------ CAUTION */
$caution: #b95e04;
$caution-shade1: #4c2600;
$caution-shade2: #753a00;
$caution-tint1: #ffd8b2;
$caution-tint2: #fff2e5;
$caution-accent1: #ffbf80;
$caution-accent2: #ffa64c;
$caution-accent3: #f98c1f;
$caution-accent4: #e57200;
$caution-accent5: #944a00;

/* ------------------------------------ PROGRAM */

$k-12: $blue3;
$k-12-accent1: $blue4;
$k-12-accent2: $blue2;
$higher-education: $purple1;
$higher-education-accent1: $purple2;
$green1: $green1;
$green2: $green2;

/* ------------------------------------ COLOR MAP */

$color-types: () !default;
$color-types: (
  'bg': background-color,
  'color': color,
  'border': border-color,
);
$color-themes: () !default;
$color-themes: map.merge(
  (
    white: (
      name: white,
      code: $white,
      color: 1,
      bg: 1,
      border: 1,
      type: base,
    ),
    black1: (
      name: black-corporate,
      code: $black1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    black2: (
      name: black2-corporate,
      code: $black2,
      color: 0,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    blue1: (
      name: blue-corporate,
      code: $blue1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    blue2: (
      name: accent1-blue-corporate,
      code: $blue2,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    blue3: (
      name: accent2-blue-corporate,
      code: $blue3,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    blue4: (
      name: k-12-accent,
      code: $blue4,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    blue5: (
      name: blue6-corporate,
      code: $blue5,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray1: (
      name: gray1-corporate,
      code: $gray1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray2: (
      name: gray2-corporate,
      code: $gray2,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray3: (
      name: gray3-corporate,
      code: $gray3,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray4: (
      name: gray4-corporate,
      code: $gray4,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray5: (
      name: digital-gray,
      code: $gray5,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray6: (
      name: gray6-corporate,
      code: $gray6,
      color: 0,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    gray7: (
      name: gray7-corporate,
      code: $gray7,
      color: 0,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    gray8: (
      name: gray8-corporate,
      code: $gray8,
      color: 0,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    yellow1: (
      name: yellow-corporate,
      code: $yellow1,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    orange1: (
      name: orange-corporate,
      code: $orange1,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    red1: (
      name: red1,
      code: $red1,
      color: 1,
      bg: 1,
      border: 1,
      type: base,
    ),
    purple1: (
      name: higher-education,
      code: $purple1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    purple2: (
      name: higher-education-accent,
      code: $purple2,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    green1: (
      name: access,
      code: $green1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    green2: (
      name: access-accent,
      code: $green2,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    green3: (
      name: green3,
      code: $green3,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
  ),
  $color-themes
);

/* ------------------------------------ PALETTES , TINT and SHADES */

$palette-colors: (
  blue2: $blue2,
  blue5: $blue5,
  purple1: $purple1,
  green1: $green1,
);
$tint-colors: (
  blue2: $blue2,
  purple1: $purple1,
  green1: $green1,
);
$accent-colors: (
  blue5: (
    $blue5-accent1,
    $blue5-accent2,
    $blue5-accent3,
    $blue5-accent4,
    $blue5-accent5,
  ),
  blue2: (
    $blue2-accent1,
    $blue2-accent2,
    $blue2-accent3,
    $blue2-accent4,
    $blue2-accent5,
  ),
  purple1: (
    $purple1-accent1,
    $purple1-accent2,
    $purple1-accent3,
    $purple1-accent4,
    $purple1-accent5,
  ),
  success: (
    $success-accent1,
    $success-accent2,
    $success-accent3,
    $success-accent4,
    $success-accent5,
  ),
  warning: (
    $warning-accent1,
    $warning-accent2,
    $warning-accent3,
    $warning-accent4,
    $warning-accent5,
  ),
  caution: (
    $caution-accent1,
    $caution-accent2,
    $caution-accent3,
    $caution-accent4,
    $caution-accent5,
  ),
);

/* RGBA(code, 0.8)
 * https://tdekoning.github.io/rgba-converter/
 * https://www.rapidtables.com/convert/color/hex-to-rgb.html
 */
$palette-svg-colors: (
  blue2: #1a84cd,
  blue5: #465fcc,
  purple1: #7e4495,
  green1: #4e9c52,
);

/* ------------------------------------ SPACER */
$spacer-v: 24;
$spacer-h: 24;

/* ------------------------------------ BORDER */
$border-color: $gray4;
$border-width: 1px;

/* ------------------------------------ BREAKPOINTS */

/* 7 breakpoints */
$grid-breakpoints: (
  xs: 1px,
  sm: 768px,
  md: 1024px,
  lg: 1248px,
  xl: 1344px,
  2xl: 1440px,
  3xl: 1728px,
) !default;
$container-max-widths: (
  xs: 0,
  sm: 720px,
  md: 976px,
  lg: 1200px,
  xl: 1296px,
  2xl: 1392px,
  3xl: 1680px,
) !default;
$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;
$enable-grid-classes: true !default;
$body-bg: $white;
$gutter-size: (24, 32, 48);

/* ------------------------------------ PRINT */
$grid-breakpoints-print: (
  xs: 1px,
  sm: 1px,
  md: 1px,
) !default;

/* printing */
$print-page-size: a3 !default;
$print-body-min-width: map.get($grid-breakpoints, 'sm') !default;

/* ------------------------------------ WIDTH */
$width-height-opt: (
  100: 100%,
  50: 50%,
  auto: auto,
) !default;

/* ------------------------------------ WIDTH VIEWPORT */
$width-vw-opt: (
  100: 100vw,
  50: 50vw,
) !default;

/* ------------------------------------ HEIGHT VIEWPORT */
$height-vh-opt: (
  100: 100vh,
  50: 50vh,
) !default;

/* ------------------------------------ TEXT */

$font-color-base: $black1;
$font-size-base: 16px;
$line-height-base: 1.5em;
$font-family-base: Roboto, sans-serif;
$font-weight-base: 400;
$line-height-base: 1.5 !default;

/* 12 */
$font-size-x-small: 0.75rem;

/* 24 */
$line-height-x-small: 2em;

/* 14 */
$font-size-small: 0.875rem;

/* 24 */
$line-height-small: 1.7143em;

/* 16 */
$line-height-small-16: 1.1429em;

/* 19 */
$font-size-md: 1.1875rem;

/* 24 */
$line-height-md: 1.2632em;
$font-size-x-large: 6rem;
$line-height-x-large: 1em;
$font-weight-bold: 700;
$font-weight-black: 900;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-h: $font-family-roboto;

/* XS (mobile first) values */
$heading: (
  h1: (
    /* 32 */ size: 2rem,
    /* 40 */ line: 1.25em,
  ),
  h2: (
    /* 28 */ size: 1.75rem,
    /* 32 */ line: 1.1429em,
  ),
  h3: (
    /* 21 */ size: 1.3125rem,
    /* 24 */ line: 1.1429em,
  ),
  h4: (
    /* 19 */ size: 1.1875rem,
    /* 24 */ line: 1.2632em,
  ),
  h5: (
    /* 16 */ size: 1rem,
    /* 24 */ line: 1.5em,
  ),
  h6: (
    /* 14 */ size: 0.875rem,
    /* 16 */ line: 1.1429em,
  ),
);
$body: (
  1: (
    size: 1rem,
    line: 1.5em,
    weight: null,
  ),
  2: (
    size: 0.875rem,
    line: 1.7143em,
    weight: null,
  ),
  3: (
    size: 1.125rem,
    line: 1.3333em,
    weight: null,
  ),
  4: (
    size: 1.4375rem,
    line: 1.0435em,
    weight: 300,
  ),
  5: (
    size: 1.4375rem,
    line: 1.0435em,
    weight: 700,
  ),
  6: (
    size: 1.5rem,
    line: 1.3333em,
    weight: 700,
  ),
);
$font-size-sm: $font-size-base * 0.875 !default;
$z-index-tooltip: 1070 !default;
$border-radius: 8px;
$border-radius-input: $border-radius;
$text-align: (left, right, center);
$cursor: (default, none, help, auto, pointer, progress, wait, not-allowed);
$font-weight: (
  thin: 100,
  extra-light: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  black: 900,
);

/* ------------------------------------ LINK */

$link-color: $blue5;
$link-active-color: $blue5-shade1;
$link-hover-color: $blue5;
$link-visited-color: $purple3;
$link-color-black: $black1;
$link-active-color-black: $gray1;
$link-hover-color-black: $black1;

/* ------------------------------------ LIST */

$list-offset-left: 24px;

/* ------------------------------------ BORDERS */
$border-type: (top, right, bottom, left);
$border-style: (solid, dashed);
$radius-size: (8, 16);
$borders-width: (1, 2, 4, 8, 16, 24);

/* ------------------------------------ SPACER, MARGIN, PADDING */

$spacers-h: (
  4: (
    math.div($spacer-h, 6),
  ),
  8: (
    math.div($spacer-h, 3),
  ),
  16: (
    16,
  ),
  24: $spacer-h,
  32: (
    32,
  ),
  72: (
    $spacer-h * 3,
  ),
);
$spacers-v: (
  8: (
    math.div($spacer-v, 3),
  ),
  16: (
    16,
  ),
  24: (
    $spacer-v,
  ),
  32: (
    32,
  ),
  48: (
    $spacer-v * 2,
  ),
  72: (
    $spacer-v * 3,
  ),
);

/* ------------------------------------ MARGIN + PADDING */
$mp-value: 4, 8, 16, 24, 32, 48, 72;
$mp-list: 'top', 'bottom', 'left', 'right';

/* ------------------------------------ LOGOS */
$svg-logos: 'logo-acorn', 'logo', 'programs-access', 'programs-accuplacer', 'cb-programs-ap', 'programs-ap',
  'programs-clep', 'programs-css-profile', 'programs-powerfaids', 'programs-pre-ap', 'programs-psat-8-9',
  'programs-psat-10', 'programs-psat-nmsqt', 'programs-sat', 'programs-springboard', 'search',
  'programs-college-planning';

/* ------------------------------------ GLYPHS */
$logo-glyphs: (
  'logo-acorn': e901,
  'logo': e902,
  'programs-access': e903,
  'programs-accuplacer': e904,
  'cb-programs-ap': e905,
  'programs-ap': e905,
  'programs-clep': e906,
  'programs-css-profile': e907,
  'programs-powerfaids': e908,
  'programs-pre-ap': e909,
  'programs-psat-8-9': e90a,
  'programs-psat-10': e90b,
  'programs-psat-nmsqt': e90c,
  'programs-sat': e90d,
  'programs-springboard': e90e,
  'search': e90f,
  'programs-college-planning': e900,
  'programs-big-future': e911,
  'higher-ed-search': e910,
  'programs-sat-suite': e912,
);

/* ------------------------------------ DISPLAYS */
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex, grid, inline-grid !default;
$overflows: visible, hidden, scroll, auto !default;
$visibility: visible, hidden !default;

/* ------------------------------------ POSITIONS */
$positions: relative, absolute, fixed, static, sticky !default;
$z-index: 1, 2, 9, 90, 99, 900, 909, 990, 999, 9999 !default;
$positions-org: top, right, bottom, left !default;

/* ------------------------------------ TEXT FORMAT */
$white-space: normal, nowrap, pre, pre-line, pre-wrap, initial, inherit !default;
$overflow-wrap: normal, anywhere, break-word, initial, inherit !default;

/* ------------------------------------ BUTTON */
$btn-font-family: $font-family-roboto;
$btn-font-color: $blue5;
$btn-font-weight: 700;

/* 14 */
$btn-font-size: 0.875rem;

/* 24 */
$btn-line-height: 1.7143em;

/* 23 */
$btn-line-height-safari: 1.6429em;
$btn-border-width: 1px;
$btn-border-color: $blue5;
$btn-border-radius: 999px;
$btn-border-radius-input: $border-radius;
$btn-padding-v: 12px;
$btn-padding-h: 24px;
$btn-font-family-sm: $font-family-roboto;
$btn-padding-sm-v: 8px;
$btn-padding-sm-h: 24px;

/* 14 */
$btn-font-size-sm: $font-size-small;

/* 16 */
$btn-line-height-sm: 1.1429em;

/* 15 */
$btn-line-height-sm-safari: 1.0714em;
$btn-padding-lg-v: 20px;
$btn-padding-lg-h: 48px;

/* 19 */
$btn-font-size-lg: $font-size-md;

/* 24 */
$btn-line-height-lg: $line-height-md;
$transition-time-s: 0.25s;
$transition-time: 0.3s;
$transition-time-l: 0.35s;
$btn-transition: all $transition-time-s ease-in-out;
$btn-disabled-opacity: 1;
$dot-width: 6px;
$dot-height: 6px;
$dot-radius: 50%;
$dot-spacing: 12px;
$dot-bg-color: transparent;
$dot-color: $blue5;
$d-max: 50px;
$d-min: 12px;
$btn-blue-action: rgb(50 77 199 / 10%);
$btn-blue-action-focus: rgb(50 77 199 / 40%);

/* ------------------------------------ FORMS */

$gray-light-border-color: #e5e2e0;
$cb-disabled-font-color: #989795;

/* forms */
$input-bk: $gray5;
$input-focus: $blue5;
$input-border: $gray4;
$input-border-hover: $gray4;
$input-border-focus: $blue5;
$input-hover-shadow: #e7e6e5;
$input-placeholder: #737373;
$input-bg-disabled: #eee;
$input-padding-v: 12px;
$input-padding-h: 16px;

/* form Validation */
$success-color: $green3;
$error-color: $red1;
$warning-color: #f90;
$success-glyph: 'cb-check';
$error-glyph: 'cb-x-mark';
$warning-glyph: 'cb-exclamation';

/* ------------------------------------ TABLES */

$table-border-color: $gray4;
$table-border-width: 1px;
$table-cell-padding: 11px 8px 12px 8px;
$table-cell-padding-light: 11px 8px 11px 8px;
$table-condensed-cell-padding: 3px 4px 4px 4px;

/* ------------------------------------ TOOLTIP */
$tooltip-font-size: $font-size-sm !default;
$tooltip-min-width: 180px !default;
$tooltip-max-width: 360px !default;
$tooltip-color: $white !default;
$tooltip-bg: $gray1 !default;
$tooltip-border-radius: $border-radius;
$tooltip-border-color: $white !default;
$tooltip-opacity: 0.9 !default;
$tooltip-spacer: 12px !default;

/* ------------------------------------ MODALS */
$z-index-modal-background: 1040;

/* higher than tooltip */
$z-index-modal-background-high: 1075;
$modal-backdrop-bg: rgb(30 30 30 / 90%);
$modal-bg: $white;
$modal-opacity: 0.9;
$modal-border-radius: $border-radius;
$modal-content-border-color: rgb(0 0 0 / 10%);

/* modal content border color **for IE8** */
$modal-content-fallback-border-color: $gray3;
$modal-spacing: 24px;

/* ------------------------------------ TYPOGRAPHY */
$column-count: (2, 3, 4);

/* ------------------------------------ TOAST */

/* transitions */
$toast-transition-ease: cubic-bezier(0.22, 0.61, 0.35, 1) !default;
$toast-transition-duration: 0.8s !default;
$z-index-toast: 1080;

/* for one line toast */
$toast-height: 48px;

/* space between two toasts */
$toast-space: 16px;
$blur-rate: 10px !default;

/* toast transition properties */
$enter-translate: (
  transform: translateY(-$toast-height) translateY(0),
  opacity: 0 1,
  filter: blur(0) blur($blur-rate),
);
$enter-translate-sibling: (
  transform: translateY(-$toast-height) translateY(0),
);
$leave-translate: (
  opacity: 0 1,
  filter: blur($blur-rate) blur(0),
);

/* ------------------------------------ DATE PICKER  */
$day-size: 36px;
$day-size-double: 40px;
$day-of-week-font-size: 14px !default;
$day-of-week-font-weight: 700 !default;
$day-font-size: 14px !default;
$day-font-weight: 500 !default;
$day-disabled-opacity: 0.3;

/* increased from .3 to .49 for A11Y */
$day-previous-next-opacity: 0.49 !default;
$today-color: $blue5 !default;
$selected-date-color: #fff !default;
$month-padding: 0;

/* Loader */
$shimmer-gradient-1: #eff1f3;
$shimmer-gradient-dark: #fffefe;
$shimmer-gradient-light: #e2e2e2;
$loader-line-value: 4, 8, 16, 24, 32, 48, 72;

/* default: 1rem */
$glyph-size: () !default;
$glyph-size: map.merge(
  (
    'xs': 1,
    'sm': 2,
    'md': 3,
    'lg': 4,
    'xl': 5,
  ),
  $glyph-size
);
$glyphs: () !default;
$glyphs: map.merge(
  (
    'online-tool': e936,
    'download': e914,
    'batch-download': e902,
    'menu-local': e925,
    'filter': e91d,
    'sort': e947,
    'sort-desc': e913,
    'sort-asc': e950,
    'up': e950,
    'down': e913,
    'left': e921,
    'right': e940,
    'dbl-left': e910,
    'dbl-right': e911,
    'mail': e928,
    'fax': e91c,
    'home': e91f,
    'location': e926,
    'phone-alt': e938,
    'resources': e93f,
    'cal-full': e907,
    'east': e916,
    'cal-empty': e906,
    'note': e935,
    'lightbulb': e922,
    'question': e93c,
    'megaphone': e92a,
    'plus': e93a,
    'minus': e92c,
    'chat-bubble': e909,
    'facebook': e91b,
    'linkedin': e923,
    'twitter': e94f,
    'youtube': e956,
    'instagram': e920,
    'compose': e90d,
    'upload': e951,
    'list': e924,
    'user': e952,
    'settings': e944,
    'error': e918,
    'book': e905,
    'tag': e94c,
    'check': e90b,
    'menu': e92b,
    'exclamation': e919,
    'draft-in': e915,
    'new-window': e930,
    'no-draft': e931,
    'nav-links': e92d,
    'quote-right': e93e,
    'quote-left': e93d,
    'x-mark': e955,
    'circle': e90c,
    'disc': e912,
    'north-east': e932,
    'creditcard': e90f,
    'educator': e917,
    'series': e943,
    'bell': e904,
    'pending': e937,
    'signed-in': e946,
    'matrix': e929,
    'search': e942,
    'cart': e908,
    'globe': e91e,
    'acorn': e957,
    'batch-upload': e903,
    'needs-score': e92e,
    'test-scored': e94e,
    'save-date': e941,
    'north-west': e933,
    'west': e953,
    'south-west': e94a,
    'south-east': e949,
    'barchart': e901,
    'print': e93b,
    'chat': e90a,
    'window': e954,
    'bag': e900,
    'expand-window': e91a,
    'task-complete': e94d,
    'new-doc': e92f,
    'sound': e948,
    'computer': e90e,
    'share': e945,
    'lock': e927,
    'phone': e939,
    'disc-alt': e958,
    'box-fill': e959,
    'box': e95a,
    'play': e95b,
    'check-alt': e95c,
    'check-circle': e95d,
    'see-on': e95e,
    'see-off': e95f,
    'exclamation-circle': e960,
    'full-screen': e961,
    'exit-full-screen': e962,
    'slideshow-play': e963,
    'slideshow-pause': e964,
    'gallery-forward': e966,
    'gallery-backward': e965,
    'carousel-backward': e967,
    'carousel-forward': e968,
    'carousel-backward-sm': e969,
    'carousel-forward-sm': e96a,
    'refresh': e96b,
    'attachment': e96c,
    'order-desc': e96d,
    'order-asc': e96e,
    'laptop': e96f,
  ),
  $glyphs
);

/* default: 1rem */
$multi-glyph-size: (
  'sm': 2,
  'lg': 4,
  'xl': 6,
);
$multi-glyph: (
  'accessible': (
    'before': e900,
    'after': e97c,
  ),
  'app': (
    'before': e901,
    'after': e97d,
  ),
  'bag': (
    'before': e902,
    'after': e97e,
  ),
  'barchart': (
    'before': e903,
    'after': e97f,
  ),
  'batch-download': (
    'before': e904,
    'after': e980,
  ),
  'batch-upload': (
    'before': e905,
    'after': e981,
  ),
  'bell': (
    'before': e906,
    'after': e982,
  ),
  'book': (
    'before': e907,
    'after': e983,
  ),
  'bullet-list': (
    'before': e908,
    'after': e984,
  ),
  'bullet-list-doc': (
    'before': e909,
    'after': e985,
  ),
  'calculator': (
    'before': e90a,
    'after': e986,
  ),
  'calendar-empty': (
    'before': e90b,
    'after': e987,
  ),
  'calendar-full': (
    'before': e90c,
    'after': e988,
  ),
  'cart': (
    'before': e90d,
    'after': e989,
  ),
  'chat': (
    'before': e90e,
    'after': e98a,
  ),
  'check': (
    'before': e910,
    'after': e98b,
  ),
  'check-list': (
    'before': e911,
    'after': e98c,
  ),
  'check-list-doc': (
    'before': e912,
    'after': e98d,
  ),
  'college-reputation': (
    'before': e914,
    'after': e98e,
  ),
  'compose': (
    'before': e915,
    'after': e98f,
  ),
  'computer': (
    'before': e916,
    'after': e990,
  ),
  'credit-card': (
    'before': e917,
    'after': e991,
  ),
  'dbl-left': (
    'before': e91a,
    'after': e994,
  ),
  'dbl-right': (
    'before': e91b,
    'after': e995,
  ),
  'dollar': (
    'before': e91d,
    'after': e997,
  ),
  'download': (
    'before': e91f,
    'after': e999,
  ),
  'educator': (
    'before': e920,
    'after': e99a,
  ),
  'error': (
    'before': e922,
    'after': null,
  ),
  'everyday-words': (
    'before': e924,
    'after': e99c,
  ),
  'expand-window': (
    'before': e926,
    'after': e99e,
  ),
  'fax': (
    'before': e929,
    'after': e9a0,
  ),
  'full-length-practice': (
    'before': e92b,
    'after': e9a1,
  ),
  'globe': (
    'before': e967,
    'after': e965,
  ),
  'graduate-pro-school': (
    'before': e92d,
    'after': e9a3,
  ),
  'graduation': (
    'before': e92e,
    'after': e9a4,
  ),
  'home': (
    'before': e92f,
    'after': e9a5,
  ),
  'information': (
    'before': e930,
    'after': e9a6,
  ),
  'instagram': (
    'before': e931,
    'after': null,
  ),
  'facebook': (
    'before': e928,
    'after': null,
  ),
  'youtube': (
    'before': e97b,
    'after': null,
  ),
  'linkedin': (
    'before': e934,
    'after': null,
  ),
  'twitter': (
    'before': e972,
    'after': null,
  ),
  'light-bulb': (
    'before': e933,
    'after': e9a8,
  ),
  'list': (
    'before': e935,
    'after': e9a9,
  ),
  'local-menu': (
    'before': e936,
    'after': e9aa,
  ),
  'location': (
    'before': e937,
    'after': e9ab,
  ),
  'lock': (
    'before': e938,
    'after': e9ac,
  ),
  'lock-open': (
    'before': e939,
    'after': e9ad,
  ),
  'mail': (
    'before': e93a,
    'after': e9ae,
  ),
  'matrix': (
    'before': e93b,
    'after': e9af,
  ),
  'megaphone': (
    'before': e93c,
    'after': e9b0,
  ),
  'menu': (
    'before': e93d,
    'after': e9b1,
  ),
  'minus': (
    'before': e93e,
    'after': e9b2,
  ),
  'nav-links': (
    'before': e93f,
    'after': e9b3,
  ),
  'new-doc': (
    'before': e941,
    'after': e9b5,
  ),
  'new-window': (
    'before': e942,
    'after': e9b6,
  ),
  'note': (
    'before': e947,
    'after': e9b9,
  ),
  'online-tool': (
    'before': e948,
    'after': e9ba,
  ),
  'pending': (
    'before': e949,
    'after': e9bb,
  ),
  'phone': (
    'before': e94a,
    'after': e9bc,
  ),
  'phone-alt': (
    'before': e94b,
    'after': e9bd,
  ),
  'plus': (
    'before': e94c,
    'after': e9be,
  ),
  'practice': (
    'before': e94d,
    'after': e9bf,
  ),
  'practice-test': (
    'before': e94e,
    'after': e9c0,
  ),
  'presentation': (
    'before': e94f,
    'after': e9c1,
  ),
  'print': (
    'before': e950,
    'after': e9c2,
  ),
  'problem-feedback': (
    'before': e951,
    'after': e9c3,
  ),
  'raided-pencil': (
    'before': e955,
    'after': e9c5,
  ),
  'resources': (
    'before': e956,
    'after': e9c6,
  ),
  'rupee': (
    'before': e958,
    'after': e9c8,
  ),
  'save-date': (
    'before': e959,
    'after': e9c9,
  ),
  'science': (
    'before': e95d,
    'after': e9cc,
  ),
  'search': (
    'before': e95e,
    'after': e9cd,
  ),
  'series': (
    'before': e95f,
    'after': e9ce,
  ),
  'settings': (
    'before': e960,
    'after': e9cf,
  ),
  'share': (
    'before': e961,
    'after': e9d0,
  ),
  'share-alt': (
    'before': e962,
    'after': e9d1,
  ),
  'sound': (
    'before': e966,
    'after': e9d4,
  ),
  'special-edition': (
    'before': e969,
    'after': e9d5,
  ),
  'tag': (
    'before': e96b,
    'after': e9d6,
  ),
  'task-complete': (
    'before': e96d,
    'after': e9d8,
  ),
  'teacher-edition': (
    'before': e96e,
    'after': e9d9,
  ),
  'team-leader': (
    'before': e96f,
    'after': e9da,
  ),
  'test-scored': (
    'before': e970,
    'after': e9db,
  ),
  'tuition-financial-aid': (
    'before': e971,
    'after': e9dc,
  ),
  'upload': (
    'before': e974,
    'after': e9de,
  ),
  'user': (
    'before': e975,
    'after': null,
  ),
  'video-lesson': (
    'before': e976,
    'after': e9df,
  ),
  'window': (
    'before': e978,
    'after': null,
  ),
  'work-together': (
    'before': e979,
    'after': e9e0,
  ),
  'quote-left': (
    'before': e913,
    'after': e91c,
  ),
  'quote-right': (
    'before': e91e,
    'after': e919,
  ),
  'add-user': (
    'before': e9e2,
    'after': e9ec,
  ),
  'dollars': (
    'before': e9e3,
    'after': e9ed,
  ),
  'essential-math': (
    'before': e9e4,
    'after': e9ee,
  ),
  'explore': (
    'before': e9e5,
    'after': e9ef,
  ),
  'face-happy': (
    'before': e9e6,
    'after': e9d3,
  ),
  'face-unhappy': (
    'before': e9e7,
    'after': e9d3,
  ),
  'face-unsure': (
    'before': e9e8,
    'after': e9d3,
  ),
  'maths': (
    'before': e9ea,
    'after': e9f3,
  ),
  'school': (
    'before': e9eb,
    'after': e9f4,
  ),
  'exam': (
    'before': e921,
    'after': e944,
  ),
  'khan-academy': (
    'before': e90f,
    'after': null,
  ),
  'send-scores': (
    'before': e925,
    'after': e945,
  ),
  'submit-documents': (
    'before': e92a,
    'after': e946,
  ),
  'submit-portfolio': (
    'before': e932,
    'after': e952,
  ),
  'play': (
    'before': e953,
    'after': null,
  ),
  'signed-in': (
    'before': e957,
    'after': e954,
  ),
  'laptop': (
    'before': e96a,
  ),
  'laptop-check': (
    'before': e96a,
    'after': e968,
  ),
);

/* ------------------------------------ ICONS  */
$icon-size: (12, 14, 16, 24, 32);
$icons: () !default;
$icons: map.merge(
  (
    'accessibility': e900,
    'acorn': e901,
    'ap': e90d,
    'apple': e902,
    'award-medal': e903,
    'book-open': e904,
    'book': e905,
    'bullet-list': e906,
    'calculator': e907,
    'calendar-add': e908,
    'calendar-save-the-date': e909,
    'cart': e981,
    'checkbox': e90a,
    'checklist': e90b,
    'clipboard-check': e90c,
    'clipboard': e9b0,
    'coin': e90e,
    'copyright': e9c0,
    'document-award': e90f,
    'dollar': e910,
    'download-alt': e911,
    'expand-window': e912,
    'explore': e913,
    'face-bad': e914,
    'face-excellent': e915,
    'face-good': e916,
    'face-neutral': e917,
    'face-very-bad': e918,
    'feedback': e919,
    'financial-aid': e91a,
    'globe': e91b,
    'graduate': e91c,
    'graph': e91d,
    'home': e91e,
    'image': e91f,
    'info-page': e920,
    'info': e921,
    'info-circle': e921,
    'khan-academy': e922,
    'laptop-exclamation': e923,
    'lightbulb': e924,
    'list': e925,
    'math-essential': e926,
    'microscope': e927,
    'new-page': e928,
    'new-window': e929,
    'note': e92a,
    'notepad-check': e92b,
    'pages': e92c,
    'paper-airplane': e92d,
    'practice-test': e92e,
    'practice': e92f,
    'quotes': e930,
    'raised-pencil': e931,
    'registration': e9c1,
    'sat': e98d,
    'school-urban': e932,
    'school': e933,
    'send-scores': e934,
    'share-alt': e935,
    'stopwatch': e936,
    'submit': e937,
    'tablet': e938,
    'task-complete': e939,
    'teacher': e93a,
    'team-leader': e93b,
    'trademark': e9c2,
    'trophy': e93c,
    'twitter': e93d,
    'university': e93e,
    'upload': e93f,
    'cal-empty': e940,
    'cal-full': e941,
    'compose': e942,
    'heart': e943,
    'hyperlink': e944,
    'minus': e945,
    'pending': e946,
    'phone': e947,
    'plus': e948,
    'settings': e949,
    'tool': e94a,
    'user-add': e94b,
    'window': e94c,
    'attachment': e94d,
    'box': e94e,
    'lg-left': e94f,
    'lg-right': e950,
    'check-circle': e9bf,
    'exclamation-circle': e9be,
    'check-fill': e951,
    'exclamation-fill': e952,
    'check': e953,
    'circle': e954,
    'box-fill': e955,
    'compose-cell': e956,
    'compose-pen': e957,
    'computer': e958,
    'disc-alt': e959,
    'disc': e95a,
    'down': e95b,
    'download': e95c,
    'east': e95d,
    'error': e95e,
    'location-tag': e95f,
    'order-asc': e960,
    'pause': e961,
    'pause-circle': e9bd,
    'exclamation': e962,
    'exit-full-screen': e963,
    'facebook': e964,
    'filter': e965,
    'full-screen': e966,
    'instagram': e968,
    'laptop': e969,
    'left': e96a,
    'linkedin': e96b,
    'location-pin': e96c,
    'order-desc': e96d,
    'location': e96e,
    'lock-open': e96f,
    'lock': e970,
    'mail': e971,
    'megaphone': e972,
    'menu': e973,
    'north-east': e975,
    'north-west': e976,
    'north': e977,
    'chat': e978,
    'bell': e979,
    'copy': e97a,
    'folder': e97b,
    'carousel-backward-sm': e97c,
    'carousel-forward-sm': e97e,
    'carousel-backward': e97f,
    'carousel-forward': e980,
    'gallery-backward': e982,
    'gallery-forward': e983,
    'play': e984,
    'play-circle': e9bc,
    'play-video': e984,
    'print': e986,
    'question': e987,
    'question-fill': e9b8,
    'question-circle': e97d,
    'refresh': e988,
    'right': e989,
    'search': e98a,
    'see-off': e98b,
    'see-on': e98c,
    'share-arrow': e98e,
    'share': e98f,
    'sort-asc': e991,
    'sort-desc': e990,
    'sort': e992,
    'south': e993,
    'tag': e994,
    'up': e995,
    'user-circle': e996,
    'user-remove': e985,
    'user': e997,
    'west': e998,
    'x-mark': e999,
    'youtube': e99a,
    'more': e967,
    'more-alt': e974,
    'vert-grab-handle': e99b,
    'undo': e99c,
    'trash': e99d,
    'text-style-off': e99e,
    'underline': e99f,
    'bold': e9a0,
    'italic': e9a1,
    'table-ico': e9a2,
    'superscript': e9a3,
    'subscript': e9a4,
    'save': e9a5,
    'bookmark': e9a6,
    'left-double': e9a7,
    'right-double': e9a8,
    'stop': e9a9,
    'redo': e9aa,
    'right-align': e9ab,
    'center-align': e9ac,
    'indent': e9ad,
    'left-align': e9ae,
    'pi': e9af,
    'paste': e9b0,
    'omega': e9b1,
    'number-list': e9b2,
    'logout': e9b3,
    'layout-right-pane': e9b4,
    'layout-50-50': e9b5,
    'layout-left-pane': e9b6,
    'archive': e9b7,
    'code': e9b9,
    'cut': e9ba,
    'horz-grab-handle': e9bb,
    'x': e9c3,
    'briefcase': e9c4,
    'cal-error': e9c5,
    'computer-list': e9c6,
    'document-check': e9c7,
    'edit': e9c8,
    'image-submit': e9c9,
    'laptop-check': e9ca,
    'list-edit': e9cb,
    'maths': e9cc,
    'page-error': e9cd,
    'page-list': e9ce,
    'pencil-ruler': e9cf,
    'pillar': e9d0,
    'presentation-alt': e9d1,
    'presentation-video': e9d2,
    'presentation': e9d3,
    'script': e9d4,
    'document-share': e9d5,
    'sound': e9d6,
    'user-check': e9d7,
    'users': e9d8,
    'window-check': e9d9,
  ),
  $icons
);
$grouped-icons-a: calendar, controls, filemanagement, filtering, navigation, statusandalert, toggle, miscellaneous;
$grouped-icons-b: brandandlogos, devices, formatting, location, reactingandrating, school, shopping, social, user;
$grouped-icons: () !default;
$grouped-icons: (
  brandandlogos: (
    'acorn': e901,
    'sat': e98d,
    'ap': e90d,
    'khan-academy': e922,
    'copyright': e9c0,
    'registration': e9c1,
    'trademark': e9c2,
  ),
  calendar: (
    'calendar-save-the-date': e909,
    'calendar-add': e908,
    'calendar-empty': e940,
    'calendar-full': e941,
    'calendar-error': e9c5,
    'cal-empty': e940,
    'cal-full': e941,
    'cal-error': e9c5,
  ),
  controls: (
    'right-double': e9a8,
    'left-double': e9a7,
    'refresh': e988,
    'full-screen': e966,
    'exit-full-screen': e963,
    'logout': e9b3,
    'edit': e9c8,
    'trash': e99d,
    'search': e98a,
    'settings': e949,
    'tool': e94a,
    'play': e984,
    'play-circle': e9bc,
    'pause': e961,
    'pause-circle': e9bd,
    'stop': e9a9,
    'sound': e9d6,
    'zoom-in': e901,
    'zoom-out': e902,
  ),
  devices: (
    'phone': e947,
    'laptop-exclamation': e923,
    'expand-window': e912,
    'computer': e958,
    'new-window': e929,
    'tablet': e938,
    'explore': e913,
    'window': e94c,
    'compose-cell': e956,
    'laptop': e969,
    'laptop-check': e9ca,
    'computer-list': e9c6,
    'window-check': e9d9,
    'presentation-alt': e9d1,
    'presentation-video': e9d2,
    'presentation': e9d3,
  ),
  filemanagement: (
    'list': e925,
    'info-page': e920,
    'note': e92a,
    'clipboard': e9b0,
    'clipboard-check': e90c,
    'notepad-check': e92b,
    'share-alt': e935,
    'task-complete': e939,
    'pages': e92c,
    'document-award': e90f,
    'submit': e937,
    'new-page': e928,
    'compose': e942,
    'mail': e971,
    'practice-test': e92e,
    'folder': e97b,
    'archive': e9b7,
    'image': e91f,
    'download': e95c,
    'download-alt': e911,
    'upload': e93f,
    'upload-alt': e943,
    'compose-pen': e957,
    'list-edit': e9cb,
    'document-share': e9d5,
    'image-submit': e9c9,
    'page-list': e9ce,
    'page-error': e9cd,
    'document-check': e9c7,
    'notes': e900,
  ),
  filtering: (
    'filter': e965,
    'order-desc': e96d,
    'order-asc': e960,
    'sort-asc': e991,
    'sort-desc': e990,
    'sort': e992,
    'tag': e994,
  ),
  formatting: (
    'undo': e99c,
    'redo': e9aa,
    'bold': e9a0,
    'italic': e9a1,
    'underline': e99f,
    'cut': e9ba,
    'copy': e97a,
    'paste': e9b0,
    'save': e9a5,
    'indent': e9ad,
    'right-align': e9ab,
    'center-align': e9ac,
    'left-align': e9ae,
    'pi': e9af,
    'omega': e9b1,
    'code': e9b9,
    'table-ico': e9a2,
    'superscript': e9a3,
    'subscript': e9a4,
    'bookmark': e9a6,
    'bullet-list': e906,
    'checklist': e90b,
    'number-list': e9b2,
    'text-style-off': e99e,
    'print': e986,
    'attachment': e94d,
    'hyperlink': e944,
    'layout-50-50': e9b5,
    'layout-right-pane': e9b4,
    'layout-left-pane': e9b6,
    'maths': e9cc,
    'script': e9d4,
  ),
  location: (
    'location-pin': e96c,
    'location': e96e,
    'location-tag': e95f,
  ),
  navigation: (
    'up': e995,
    'right': e989,
    'left': e96a,
    'down': e95b,
    'north-east': e975,
    'north-west': e976,
    'north': e977,
    'south': e993,
    'east': e95d,
    'west': e998,
    'home': e91e,
    'menu': e973,
    'more': e967,
    'more-alt': e974,
    'plus': e948,
    'minus': e945,
    'horz-grab-handle': e9bb,
    'vert-grab-handle': e99b,
    'x-mark': e999,
  ),
  reactingandrating: (
    'face-bad': e914,
    'face-excellent': e915,
    'face-good': e916,
    'face-neutral': e917,
    'face-very-bad': e918,
    'heart': e943,
    'star': e908,
  ),
  school: (
    'apple': e902,
    'award-medal': e903,
    'book-open': e904,
    'book': e905,
    'practice': e92f,
    'raised-pencil': e931,
    'school-urban': e932,
    'school': e933,
    'send-scores': e934,
    'teacher': e93a,
    'team-leader': e93b,
    'trophy': e93c,
    'university': e93e,
    'financial-aid': e91a,
    'globe': e91b,
    'graduate': e91c,
    'graph': e91d,
    'calculator': e907,
    'microscope': e927,
    'math-essential': e926,
    'stopwatch': e936,
    'quotes': e930,
    'paper-airplane': e92d,
    'lightbulb': e924,
    'pillar': e9d0,
    'pencil-ruler': e9cf,
    'briefcase': e9c4,
  ),
  shopping: (
    'dollar': e910,
    'coin': e90e,
    'cart': e981,
  ),
  social: (
    'facebook': e964,
    'instagram': e968,
    'youtube': e99a,
    'x': e9c3,
    'linkedin': e96b,
    'chat': e978,
    'feedback': e919,
    'share-arrow': e98e,
    'share': e98f,
    'twitter': e93d,
  ),
  statusandalert: (
    'error': e95e,
    'exclamation-circle': e9be,
    'exclamation-fill': e952,
    'exclamation': e962,
    'question': e987,
    'question-fill': e9b8,
    'question-circle': e97d,
    'check-circle': e9bf,
    'check-fill': e951,
    'check': e953,
    'info-circle': e921,
    'pending': e946,
    'bell': e979,
    'megaphone': e972,
  ),
  toggle: (
    'box-fill': e955,
    'box': e94e,
    'checkbox': e90a,
    'disc-alt': e959,
    'disc': e95a,
    'circle': e954,
    'see-off': e98b,
    'see-on': e98c,
    'lock-open': e96f,
    'lock': e970,
  ),
  user: (
    'user-circle': e996,
    'user-remove': e985,
    'user-add': e94b,
    'user': e997,
    'user-check': e9d7,
    'users': e9d8,
    'accessibility': e900,
  ),
  miscellaneous: (
    'lg-left': e94f,
    'lg-right': e950,
    'carousel-backward-sm': e97c,
    'carousel-forward-sm': e97e,
    'carousel-backward': e97f,
    'carousel-forward': e980,
    'gallery-backward': e982,
    'gallery-forward': e983,
    'play-video': e984,
  ),
);

/* ------------------------------------ ILLUSTRATION  */
$illustrations: (
  'accessibility',
  'acorn',
  'add',
  'ap',
  'apple',
  'arrow-right',
  'attach',
  'award-medal',
  'award',
  'bell',
  'book-index',
  'book-open',
  'briefcase',
  'browser-add',
  'browser-check',
  'browser-expand',
  'browser',
  'bullet-list-device',
  'bullet-list-doc',
  'bullet-list',
  'calculator',
  'calendar-add',
  'calendar-empty',
  'calendar-exclamation',
  'calendar-full',
  'calendar-save-the-date',
  'cart',
  'chat',
  'checkbox',
  'checklist-device',
  'checklist-doc',
  'checklist',
  'checkmark',
  'clock',
  'coin',
  'column',
  'compose-alt',
  'compose',
  'computer-bullet-list',
  'computer',
  'condense',
  'document-award',
  'dollar',
  'download',
  'error',
  'exclamation',
  'expand',
  'computer-explore',
  'face-bad',
  'face-excellent',
  'face-good',
  'face-neutral',
  'face-very-bad',
  'feedback',
  'financial-aid',
  'funnel',
  'settings',
  'globe',
  'graduate',
  'graph-bar',
  'heart',
  'home',
  'info',
  'khan-academy',
  'laptop-check',
  'laptop-exclamation',
  'laptop',
  'light-bulb',
  'list-alt',
  'list-pencil',
  'list',
  'location',
  'lock',
  'mail-compose',
  'mail-send',
  'math-alt',
  'math-essential',
  'math',
  'megaphone',
  'new-page',
  'information',
  'note',
  'notepad-check',
  'pages',
  'paper-airplane',
  'pause',
  'pencil',
  'phone',
  'photo',
  'play',
  'practice-alt',
  'practice',
  'presentation-bar-graph',
  'presentation',
  'print',
  'question',
  'quotes',
  'raised-pencil',
  'refresh',
  'remove-page',
  'remove',
  'right-arrow',
  'sat',
  'school-urban',
  'school',
  'science',
  'scroll',
  'search',
  'send-scores',
  'share-alt',
  'share-link',
  'share',
  'facebook',
  'instagram',
  'linkedin',
  'tiktok',
  'x',
  'youtube',
  'sound',
  'stopwatch',
  'submit-photo',
  'submit',
  'task-complete-alt',
  'task-complete',
  'star',
  'university',
  'unlock',
  'up-arrow',
  'up-down-arrow',
  'upload',
  'user-add',
  'user-approve',
  'user-remove',
  'user',
  'users',
  'wrench'
);
