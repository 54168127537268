/*
 * CB Typography Mixins
 */

@use '../includes/apricot-variable' as *;
@use '../mixins/breakpoint' as *;
@use '../mixins/glyph' as *;
@use '../mixins/link' as *;

@mixin list-style() {
  ol:not([class^='cb-text-list']),
  ul:not([class^='cb-text-list']) {
    li {
      list-style: circle;
    }

    ol:not([class^='cb-text-list']),
    ul:not([class^='cb-text-list']) {
      li {
        text-indent: -5px;
        list-style-type: none;

        &::before {
          content: '-';
          position: relative;
          left: -12px;
        }
      }
    }
  }
}

@mixin list-checkbox() {
  li {
    position: relative;
    padding-left: 8px;
    list-style: none;

    &::before {
      @include apricot-font($icons-font-family-group-a);

      content: '\e94e';

      position: absolute;
      top: 4px;
      left: -17px;

      font-size: 1rem;
    }
  }
}

@mixin list-checkmark() {
  li {
    position: relative;
    padding-left: 8px;
    list-style: none;

    &::before {
      @include apricot-font($icons-font-family-group-a);

      content: '\e953';

      position: absolute;
      top: 4px;
      left: -17px;

      font-size: 1rem;
    }
  }
}

@mixin cb-text-list-feature() {
  padding-left: 0;
  border: 1px solid $border-color;

  li {
    padding: 16px;
    list-style: none;
    border-bottom: 1px solid $border-color;

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

@mixin cb-text-list-checkmark() {
  li {
    &::before {
      content: '\e953';

      position: unset;
      top: 0;
      left: 0;

      margin-right: 8px;

      font-size: 1rem;

      @include apricot-font($icons-font-family-group-a);
    }

    p {
      display: inline;
    }
  }
}

@mixin list-dash() {
  li {
    position: relative;
    padding-left: 8px;
    list-style: none;

    &::before {
      content: '\2014';

      position: absolute;
      top: 0;
      left: -17px;

      font-size: 1rem;
    }
  }
}

/* ------------------------------------ META */
@mixin cb-text-meta() {
  /* 12 */
  font-size: 0.75rem;
  font-weight: 500;

  /* 16 */
  line-height: 1.3333em;
}

@mixin cb-text-meta-large() {
  /* 14 */
  font-size: $font-size-small;
  font-weight: 500;

  /* 24 */
  line-height: $line-height-small;
}

/* ------------------------------------ PARAGRAPH */

@mixin cb-paragraph2-res() {
  /* xs */

  /* 21 */
  font-size: 1.3125rem;

  /* 24 */
  line-height: 1.1429em;

  /* sm, md */
  @include media-breakpoint-up('sm', $grid-breakpoints) {
    /* 24 */
    font-size: 1.5rem;

    /* 32 */
    line-height: 1.3333em;
  }

  /* lg and up */
  @include media-breakpoint-up('lg', $grid-breakpoints) {
    /* 32 */
    font-size: 2rem;

    /* 40 */
    line-height: 1.25em;
  }
}
