/*
 * CB D8 Mixins
 */

@use 'sass:map';

@use '../includes/apricot-variable' as *;
@use '../mixins/link' as *;
@use '../mixins/image' as *;
@use '../mixins/table' as *;
@use '../mixins/breakpoint' as *;

@mixin white-color-text {
  .d8-text-list-feature {
    ul {
      li {
        a {
          @include white-link();
        }
      }
    }
  }

  * {
    color: $white;
    border-color: $white;
  }

  a:not(.cb-btn) {
    @include white-link();
  }

  .cb-btn {
    &[class^='cb-btn-light'] {
      border-color: $white;
    }

    &.cb-btn-primary,
    &.cb-btn-primary * {
      color: $blue5;

      &:visited {
        color: $blue5;
      }

      &[class*='cb-btn-light'] {
        color: $blue5;

        &:visited {
          color: $blue5;

          &:focus {
            &:hover {
              color: $white;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &.cb-btn-yellow,
    &.cb-btn-yellow * {
      color: $black1;

      &:visited {
        color: $black1;
      }

      &[class*='cb-btn-light'] {
        color: $black1;

        &:visited {
          color: $black1;
        }
      }
    }

    &.cb-btn-black,
    &.cb-btn-black * {
      color: $black1;

      &:visited {
        color: $black1;
      }
    }
  }

  .cb-date-time-tags {
    p {
      &.cb-event-stamp {
        color: $black1;
      }
    }
  }

  .cb-menu-list {
    a {
      &.cb-menu-link {
        /* white */
        @include white-link();

        &:hover,
        &.cb-hover,
        &:focus,
        &.cb-focus {
          background-color: rgb(0 0 0 / 20%);
        }
      }
    }

    &.cb-menu-list-horizontal {
      a {
        &.cb-selected,
        &:hover,
        &.cb-hover,
        &:focus,
        &.cb-focus {
          box-shadow: inset 0 -3px 0 -1px $white !important;
        }
      }
    }

    &.cb-menu-list-vertical {
      a {
        &.cb-selected,
        &:hover,
        &.cb-hover,
        &:focus,
        &.cb-focus {
          box-shadow: inset 2px 0 0 0 $white !important;
        }
      }
    }

    &.cb-menu-list-d9 {
      &.cb-menu-list-vertical {
        a {
          &.cb-selected,
          &:hover,
          &.cb-hover,
          &:focus,
          &.cb-focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .cb-card {
    .cb-text-overline-small,
    .cb-text-overline,
    .cb-meta-tags *,
    .cb-card-action * {
      color: $black1 !important;
    }
  }
}

@mixin black-color-text {
  .d8-text-list-feature {
    ul {
      li {
        a {
          @include black-link();
        }
      }
    }
  }

  * {
    color: $black;
    border-color: $black;
  }

  a {
    @include default-link();
  }

  .cb-btn {
    text-decoration: none;

    &.cb-btn-light {
      &:visited {
        color: $blue5;
      }
    }

    &.cb-btn-primary {
      color: $white;
      border-color: $blue5;

      &:visited {
        color: $white;
      }

      &[class*='cb-btn-light'] {
        &:visited {
          color: $white;
        }
      }
    }

    &.cb-btn-light {
      border-color: $blue5;

      &.cb-btn-black {
        border-color: $black1;
      }
    }
  }
}

/* ------------------------------------ WYSIWYG */
@mixin wysiwyg {
  width: 100%;

  /* ------------------------------------ HEADING */

  /* xs */
  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1em;
  }

  h3 {
    font-size: map.get(map.get($heading, h4), size);
    font-weight: 500;
    line-height: map.get(map.get($heading, h4), line);
  }

  h4 {
    font-size: map.get(map.get($heading, h5), size);
    font-weight: 500;
    line-height: map.get(map.get($heading, h5), line);
  }

  h5 {
    font-size: map.get(map.get($heading, h6), size);
    font-weight: 700;
    line-height: map.get(map.get($heading, h6), line);
  }

  h6 {
    font-weight: 500;
  }

  @include media-breakpoint-only('xs', $grid-breakpoints) {
    h2 {
      font-weight: 500;
    }
  }

  /* sm, md */
  @include media-breakpoint-up('sm', $grid-breakpoints) {
    h2 {
      /* 28 */
      font-size: 1.75rem;

      /* 32 */
      line-height: 1.1429em;
    }

    h3 {
      /* 21 */
      font-size: 1.3125rem;

      /* 24 */
      line-height: 1.1429em;
    }

    h4 {
      /* 18 */
      font-size: 1.125rem;

      /* 24 */
      line-height: 1.3333em;
    }

    h5 {
      /* 16 */
      font-size: 1rem;

      /* 24 */
      line-height: 1.5em;
    }
  }

  /* lg, xl, 2xl, 3xl */
  @include media-breakpoint-up('lg', $grid-breakpoints) {
    h2 {
      /* 32 */
      font-size: 2rem;

      /* 40 */
      line-height: 1.25em;
    }

    h3 {
      /* 26 */
      font-size: 1.625rem;

      /* 32 */
      line-height: 1.2308em;
    }

    h4 {
      /* 21 */
      font-size: 1.3125rem;

      /* 24 */
      line-height: 1.1429em;
    }

    h5 {
      /* 19 */
      font-size: 1.1875rem;

      /* 24 */
      line-height: 1.2632em;
    }
  }

  /* margin */
  @include media-breakpoint-down('lg', $grid-breakpoints) {
    h2 {
      margin-top: 32px;
      margin-bottom: 16px;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }

  @include media-breakpoint-up('xl', $grid-breakpoints) {
    h2 {
      margin-top: 48px;
      margin-bottom: 16px;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-top: 32px;
      margin-bottom: 8px;
    }
  }

  h2:first-child {
    margin-top: 0;
  }

  @for $i from 2 through 5 {
    $j: $i + 1;

    h#{$i} + h#{$i} {
      margin-top: 0 !important;
    }

    h#{$i} + h#{$j} {
      margin-top: 0 !important;
    }
  }

  @for $i from 5 through 2 {
    $j: $i - 1;

    h#{$i} + h#{$j} {
      margin-top: 0 !important;
    }
  }

  /* ------------------------------------ PARAGRAPH */

  p {
    /* xs */
    font-size: 1rem;
    line-height: 1.5em;

    /* lg and up */
    @include media-breakpoint-up('lg', $grid-breakpoints) {
      /* 21 */
      font-size: 1.3125rem;

      /* 32 */
      line-height: 1.5238em;
    }
  }

  /* ------------------------------------ IMAGE */

  img {
    @include img-fluid();

    @include media-breakpoint-down('lg', $grid-breakpoints) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    @include media-breakpoint-up('xl', $grid-breakpoints) {
      margin-top: 48px;
      margin-bottom: 48px;
    }
  }

  /* ------------------------------------ TABLES */
  table {
    @include table();

    @include media-breakpoint-down('lg', $grid-breakpoints) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    @include media-breakpoint-up('xl', $grid-breakpoints) {
      margin-top: 48px;
      margin-bottom: 48px;
    }
  }

  /* ------------------------------------ LIST */
  ul,
  ol {
    margin: 24px 0;

    li {
      font-size: 1rem;
      line-height: 1.5em;
    }

    @include media-breakpoint-up('lg', $grid-breakpoints) {
      margin: 32px 0;

      li {
        /* 21 */
        font-size: 1.3125rem;

        /* 32 */
        line-height: 1.5238em;
      }
    }
  }

  .dx-video-embed {
    @include media-breakpoint-down('lg', $grid-breakpoints) {
      margin-top: 32px;
      margin-bottom: 8px;
    }

    @include media-breakpoint-up('xl', $grid-breakpoints) {
      margin-top: 48px;
      margin-bottom: 24px;
    }

    img {
      margin: 0;
    }
  }
}
