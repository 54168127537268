/*
 * CB Focus Handling
 */

@use 'apricot-variable' as *;
@use '../mixins/utils' as *;

[data-cb-input-m='mouse'] {
  :focus {
    transition: box-shadow $transition-time;

    @include cb-no-focus();
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  a {
    &.cb-custom-outline {
      &:hover,
      &:focus {
        outline: none !important;
        box-shadow: none !important;

        &::after {
          content: '';

          position: absolute;
          top: -8px;
          left: -8px;

          display: block;

          width: calc(100% + 16px);
          height: calc(100% + 16px);

          border-radius: inherit;
          box-shadow: inset 0 0 0 3px $blue5;
        }
      }
    }
  }
}
