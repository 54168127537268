/*
 * CB List
 */

@use 'apricot-variable' as *;
@use '../mixins/utils' as *;
@use '../mixins/typography' as *;

/* ------------------------------------ BASE */
ul,
ol {
  padding-left: ($list-offset-left * 2);

  li {
    ul,
    ol {
      padding-left: $list-offset-left;
    }

    ul {
      list-style: disc;
    }
  }
}

@include namespace() {
  ul {
    li {
      list-style: disc;
    }

    @include list-style();
  }
}

ul {
  &.cb-text-list {
    li {
      list-style: disc;
    }

    @include list-style();
  }

  &.cb-text-list-checkbox {
    @include list-checkbox();

    ul:not([class^='cb-text-list']),
    ol:not([class^='cb-text-list']) {
      @include list-checkbox();

      ul:not([class^='cb-text-list']),
      ol:not([class^='cb-text-list']) {
        @include list-checkbox();
      }
    }
  }

  &.cb-text-list-checkmark {
    @include list-checkmark();

    ul:not([class^='cb-text-list']),
    ol:not([class^='cb-text-list']) {
      @include list-checkmark();

      ul:not([class^='cb-text-list']),
      ol:not([class^='cb-text-list']) {
        @include list-checkmark();
      }
    }
  }

  &.cb-text-list-dash {
    @include list-dash();

    ul:not([class^='cb-text-list']),
    ol:not([class^='cb-text-list']) {
      @include list-dash();

      ul:not([class^='cb-text-list']),
      ol:not([class^='cb-text-list']) {
        @include list-dash();
      }
    }
  }

  &.cb-no-text-list {
    list-style: none !important;

    li {
      list-style: none !important;

      ol:not([class^='cb-text-list']),
      ul:not([class^='cb-text-list']) {
        li {
          list-style: none !important;

          &::before {
            content: '' !important;
          }
        }
      }
    }
  }

  &.cb-text-list-no-indent {
    padding-left: 0 !important;

    ol:not([class^='cb-text-list']),
    ul:not([class^='cb-text-list']) {
      padding-left: 0 !important;

      li {
        text-indent: 0 !important;
      }
    }

    &:not(.cb-list-no-style),
    &:not(.cb-text-list-no-bullet),
    &:not(.cb-no-text-list) {
      li {
        margin-left: 18px !important;
      }
    }

    &.cb-list-no-style,
    &.cb-text-list-no-bullet,
    &.cb-no-text-list {
      li {
        margin-left: 0 !important;
      }
    }
  }

  &.cb-text-list-no-bullet {
    list-style-type: none;
  }

  &.cb-text-list-feature {
    @include cb-text-list-feature();

    &.cb-text-list-checkmark {
      @include cb-text-list-checkmark();
    }
  }

  /* ------------------------------------ SINGLE ITEM STYLING */
  li {
    &.cb-no-text-list {
      list-style: none !important;

      &::before {
        content: '' !important;
      }
    }
  }
}

ol {
  &.cb-text-list {
    @include list-style();
  }

  &.cb-text-list-order {
    margin: 0;
    list-style-type: none;

    > li {
      counter-increment: step1-counter;
      position: relative;
      margin-bottom: 16px;

      &::before {
        content: counter(step1-counter);

        position: absolute;
        top: -5px;
        left: -48px;

        display: inline-block;

        box-sizing: border-box;
        width: 2em;
        height: 2em;
        margin-right: 16px;

        font-weight: 700;
        line-height: 2em;
        color: $white;
        text-align: center;

        background-color: $blue5;
        border-radius: 50%;
      }
    }

    @include list-style();

    ul,
    ol {
      margin-left: 24px;
      padding: 0;

      ul,
      ol {
        margin-left: 24px;
      }
    }
  }

  &.cb-text-list-no-indent {
    padding-left: 0 !important;

    ul,
    ol {
      padding-left: 0 !important;
    }

    li {
      margin-left: 18px !important;
    }
  }
}

dt {
  margin-left: $list-offset-left;
  font-weight: 700;
}

dd {
  margin-left: $list-offset-left;
}

dl {
  &.cb-text-list-no-indent {
    dt,
    dd {
      margin-left: 0;
    }
  }
}
